import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

function onDateChange(date) {
  window.calendarDateChanged && window.calendarDateChanged(date);
}

const MONTHS = [
  I18n.t("date.month_names")[1],
  I18n.t("date.month_names")[2],
  I18n.t("date.month_names")[3],
  I18n.t("date.month_names")[4],
  I18n.t("date.month_names")[5],
  I18n.t("date.month_names")[6],
  I18n.t("date.month_names")[7],
  I18n.t("date.month_names")[8],
  I18n.t("date.month_names")[9],
  I18n.t("date.month_names")[10],
  I18n.t("date.month_names")[11],
  I18n.t("date.month_names")[12],
];
const WEEKDAYS_LONG = [
  I18n.t("date.day_names")[0],
  I18n.t("date.day_names")[1],
  I18n.t("date.day_names")[2],
  I18n.t("date.day_names")[3],
  I18n.t("date.day_names")[4],
  I18n.t("date.day_names")[5],
  I18n.t("date.day_names")[6],
];
const WEEKDAYS_SHORT = [I18n.t("date.abbr_day_names")[0], I18n.t("date.abbr_day_names")[1], I18n.t("date.abbr_day_names")[2], I18n.t("date.abbr_day_names")[3], I18n.t("date.abbr_day_names")[4], I18n.t("date.abbr_day_names")[5], I18n.t("date.abbr_day_names")[6]];

export default function Calendar({selectedDay}) {
  let range = {
    after: new Date(window.after_date),
    before: new Date(window.before_date),
  }
  if (selectedDay) {
    selectedDay = new Date(selectedDay);
  } else {
    selectedDay = new Date();
  }
  return <DayPicker disabledDays={range} selectedDays={selectedDay} onDayClick={onDateChange} month={selectedDay}
      months={MONTHS}
      weekdaysLong={WEEKDAYS_LONG}
      weekdaysShort={WEEKDAYS_SHORT}
      firstDayOfWeek={0} />;
}
