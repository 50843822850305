import React from 'react';
import ReactDOM from 'react-dom';
import Calendar from './calendar';

function renderCalendar(options) {
  if (document.getElementById('react-calendar')) {
    ReactDOM.render(
      <Calendar {...options} />,
      document.getElementById('react-calendar')
    );
  }
}

renderCalendar()

window.setCalendarDate = (selectedDay) => {
  renderCalendar({selectedDay});
}
